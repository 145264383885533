export const getColorHex = (color) => {
  return getComputedStyle(document.documentElement, null).getPropertyValue(
    "--" + color
  );
};

export const getFeedingHex = (index, singleFeeding) => {
  if (singleFeeding) {
    index = 0;
  }
  return getColorHex(getFeedingColor(index));
};

export const getFeedingColor = (index) => {
  switch (index) {
    case 0:
      return "green";
    case 1:
      return "orange";
    case 2:
      return "black";
    case 3:
      return "gray-3";
    case 4:
      return "tan";
  }
};

export const maxHeightColor = (m, returnClass) => {
  switch (Math.abs(Math.floor(m))) {
    case 0:
      return returnClass ? "btn-outline-tan" : getColorHex("tan");
    case 1:
      return returnClass ? "btn-yellow" : getColorHex("yellow");
    case 2:
      return returnClass ? "btn-green-2" : getColorHex("green-2");
    default:
      return returnClass ? "btn-green" : getColorHex("green");
  }
};
