import { getFeedingHex } from "./graphColorHelpers";

export const addFeeding = (
  chart,
  feedingNumber,
  vitals,
  maxHeights,
  feedingIndexes,
  singleFeeding
) => {
  addFeedingRegion(
    chart,
    feedingNumber,
    vitals,
    maxHeights,
    "young",
    feedingIndexes,
    singleFeeding
  );
};

const addFeedingRegion = (
  chart,
  feedingNumber,
  vitals,
  maxHeights,
  starterState,
  feedingIndexes,
  singleFeeding
) => {
  const c = {
    label: `feeding_${feedingNumber}`,
    number: feedingNumber,
    fedAt: vitals[0] ? vitals[0].fed_at : null,
    starterState: starterState,
    yAxisID: "height-y-axis-0",
    fill: false,
    lineTension: 0.1,
    borderColor: (_) => {
      return getFeedingHex(feedingIndexes[feedingNumber], singleFeeding);
    },
    borderCapStyle: "square",
    borderDashOffset: 0.0,
    borderDash: (_) => {
      if (maxHeights[feedingNumber].height < 0.1) {
        return [1];
      }
      switch (starterState) {
        case "fermenting":
          return [10, 10];
        case "mature":
          return [10, 10];
        default:
          return [1];
      }
    },
    borderJoinStyle: "miter",
    pointHitRadius: 10,
    pointRadius: (context) => {
      if (maxHeights[feedingNumber].height < 0.1) {
        return 0;
      } else {
        const index = context.dataIndex;
        if (vitals.filter((v) => v.ripe).length > 0) {
          return context.dataset.data[index].maxHeight ? 8 : 0;
        } else {
          return 0;
        }
      }
    },
    data: vitals.map((d) => {
      return {
        x: d.x,
        y: d.height,
        tick: d.tick,
        maxHeight: d.x === maxHeights[feedingNumber].x,
      };
    }),
    spanGaps: true,
  };

  chart.data.labels.push(feedingNumber);
  chart.data.datasets.push(c);
  chart.update();
};

export const removeFeeding = (chart, feedingNumber) => {
  chart.data.labels.push(feedingNumber);
  chart.data.datasets = chart.data.datasets.filter(
    (d) => d.label !== `feeding_${feedingNumber}`
  );
  chart.update();
};
