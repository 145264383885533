import { Chart } from "chart.js";
import _ from "lodash";
import { getFeedingHex } from "./graphColorHelpers";
import { tempUnitLabel, toCorrectTempUnit } from "./temperatureHelpers";

export const setupTempChart = (ctx, isCelsius) => {
  return new Chart(ctx, {
    type: "line",
    data: {
      datasets: [],
    },
    options: {
      annotation: {
        annotations: [
          {
            type: "box",
            drawTime: "beforeDatasetsDraw",
            id: "cold",
            xScaleID: "temp-x-axis-0",
            yScaleID: "temp-y-axis-0",
            yMin: toCorrectTempUnit(50, isCelsius),
            yMax: toCorrectTempUnit(60, isCelsius),
            backgroundColor: "rgba(241, 234, 215, 0.25)",
          },
          {
            type: "box",
            drawTime: "beforeDatasetsDraw",
            id: "warm",
            xScaleID: "temp-x-axis-0",
            yScaleID: "temp-y-axis-0",
            yMin: toCorrectTempUnit(60, isCelsius),
            yMax: toCorrectTempUnit(70, isCelsius),
            backgroundColor: "rgba(237, 187, 111, 0.25)",
          },
          {
            type: "box",
            drawTime: "beforeDatasetsDraw",
            id: "ideal",
            xScaleID: "temp-x-axis-0",
            yScaleID: "temp-y-axis-0",
            yMin: toCorrectTempUnit(70, isCelsius),
            yMax: toCorrectTempUnit(80, isCelsius),
            backgroundColor: "rgba(139, 173, 94, 0.25)",
          },
          {
            type: "box",
            drawTime: "beforeDatasetsDraw",
            id: "hot",
            xScaleID: "temp-x-axis-0",
            yScaleID: "temp-y-axis-0",
            yMin: toCorrectTempUnit(80, isCelsius),
            yMax: toCorrectTempUnit(100, isCelsius),
            backgroundColor: "rgba(224, 105, 53, 0.25)",
          },
        ],
      },
      responsive: true,
      tooltips: {},
      events: ["mousemove"],
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      animation: {
        duration: 0,
      },
      elements: {
        point: {},
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            id: "temp-y-axis-0",
            scaleLabel: {
              labelString: "Temperature",
              fontFamily: "Bianco Sans",
              fontSize: 18,
              lineHeight: 1.5,
              display: true,
            },
            ticks: {
              min: toCorrectTempUnit(50, isCelsius),
              max: toCorrectTempUnit(95, isCelsius),
              maxTicksLimit: 8,
              callback: (value) => {
                if (value > 0) {
                  return value.toFixed(0) + tempUnitLabel(isCelsius);
                }
              },
            },
          },
        ],
        xAxes: [
          {
            type: "linear",
            id: "temp-x-axis-0",
            display: true,
            scaleLabel: {
              fontFamily: "Bianco Sans",
              fontSize: 18,
              lineHeight: 1.5,
              display: true,
            },
            ticks: {
              stepSize: 60 * 3,
              fontFamily: "Bianco Sans",
              suggestedMax: 12*60,
              callback: (value) => {
                if (value === 0) {
                  return;
                } else {
                  return "+" + value / 60 + " hrs";
                }
              },
            },
          },
        ],
      },
    },
  });
};

export const addTemperature = (
  chart,
  feedingNumber,
  vitals,
  feedingIndexes,
  singleFeeding,
  isCelsius
) => {
  const tempDataset = {
    label: `feeding_${feedingNumber}`,
    number: feedingNumber,
    fill: false,
    lineTension: 0.1,
    borderCapStyle: "square",
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointRadius: 0,
    borderWidth: 1.5,
    borderColor: getFeedingHex(feedingIndexes[feedingNumber], singleFeeding),
    pointHitRadius: 10,
    data: vitals.map((d) => {
      return {
        x: d.x,
        y: toCorrectTempUnit(d.temperature, isCelsius),
        tick: d.tick,
      };
    }),
    spanGaps: true,
  };
  chart.data.datasets.push(tempDataset);
  chart.update();
};
