import Chart from "chart.js";

import { getColorHex } from "./graphColorHelpers";
import { toCorrectTempUnit } from "./temperatureHelpers";
import setupGauge from "./gauge";

setupGauge();

export const renderTempGauge = (gaugeContext, temperature, isCelsius) => {
  new Chart(gaugeContext, {
    type: "tsgauge",
    data: {
      datasets: [
        {
          backgroundColor: [
            getColorHex("orange"),
            getColorHex("yellow"),
            getColorHex("green-2"),
            getColorHex("orange"),
          ],
          borderWidth: 0,
          gaugeData: {
            value: toCorrectTempUnit(temperature, isCelsius).toFixed(0),
            valueColor: "#ff7143",
          },
          gaugeLimits: isCelsius ? [10, 15, 20, 35, 45] : [50, 60, 70, 90, 110],
        },
      ],
    },
    options: {
      events: [],
      showMarkers: true,
    },
  });
};
