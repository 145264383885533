import { Controller } from "@hotwired/stimulus";
import moment from "moment-timezone";
import _ from "lodash";

import { drawJarHeight } from "../packs/starterGraph/drawJarHeight";
import {
  addFeeding,
  removeFeeding,
} from "../packs/starterGraph/feedingChartHelpers";
import { getFeedingColor } from "../packs/starterGraph/graphColorHelpers";
import { renderTempGauge } from "../packs/starterGraph/renderTempGauge";
import { setupHeightChart } from "../packs/starterGraph/setupHeightChart";
import { setupMaxHeights } from "../packs/starterGraph/setupMaxHeights";
import {
  addTemperature,
  setupTempChart,
} from "../packs/starterGraph/setupTempChart";
import {
  tempUnitLabel,
  toCorrectTempUnit,
} from "../packs/starterGraph/temperatureHelpers";

// Connects to data-controller="starter-graph"
export default class extends Controller {
  static values = {
    starterId: Number,
    feedings: Array,
    mostRecentVital: Object,
    celsius: Boolean,
    refreshInterval: Number,
    userTimeZone: String,
    singleFeeding: Boolean,
  };

  static targets = [
    "tempGauge",
    "loadFeedingBtn",
    "heightChart",
    "tempChart",
    "maxHeightsChart",
  ];

  connect() {
    moment.tz.setDefault(this.userTimeZoneValue);
    this.updateData();
    // TODO: jar height probably needs to be re-drawn every time data is updated
    drawJarHeight(this.mostRecentVitalValue.height);
    this.renderTempGauge();
    this.setFeedingBtnColors();

    // Refresh user's data based on a slightly longer window than the REFRESH_INTERVAL so that it's always fresh.
    const refreshIntervalMillis = (this.refreshIntervalValue + 60) * 1000;
    setInterval(() => this.updateData(), refreshIntervalMillis);
  }

  renderTempGauge() {
    if (!this.hasTempGaugeTarget) return;

    const gaugeContext = this.tempGaugeTarget.getContext("2d");
    // TODO: This also probably needs to be re-drawn every time data is updated
    renderTempGauge(
      gaugeContext,
      this.mostRecentVitalValue.temperature,
      this.celsiusValue
    );
  }

  setFeedingBtnColors() {
    this.loadFeedingBtnTargets.forEach((btn, i) => {
      if (i !== 0) {
        btn.classList.add("btn-outline-" + getFeedingColor(i));
      }
    });
  }

  setupHeightChart() {
    if (!this.hasHeightChartTarget) return;

    const heightChartContext = this.heightChartTarget.getContext("2d");
    return setupHeightChart(heightChartContext);
  }

  setupTempChart() {
    if (!this.hasTempChartTarget) return;

    const tempChartContext = this.tempChartTarget.getContext("2d");
    return setupTempChart(tempChartContext, this.celsiusValue);
  }

  setupMaxHeights(maxHeights) {
    if (!this.hasMaxHeightsChartTarget) return;

    const maxHeightsChartContext = this.maxHeightsChartTarget.getContext("2d");
    return setupMaxHeights(maxHeightsChartContext, maxHeights);
  }

  async updateData() {
    /* This function queries the server and updates:
     * this.vitals
     * this.maxHeights
     * this.feedingIndexes
     * this.mostRecentFeedingNumber
     * With this data, it re-draws (I think) the temp, height and max heights charts
     * */

    if (this.feedingsValue.length > 0) {
      const params = new URLSearchParams({
        starter_id: this.starterIdValue,
      });
      this.feedingsValue.forEach((f) =>
        params.append("feeding_numbers[]", f.number)
      );

      let data = await fetch("/graph_data?" + params);
      data = await data.json();
      data = data.flat();
      this.vitals = _.groupBy(data, "feeding_number");

      // debugger

      const feedingNumbersWithVitals = Object.keys(this.vitals);
      feedingNumbersWithVitals.forEach((key) => {
        this.vitals[key] = _.sortBy(this.vitals[key], "x");
      });
      this.#calculateFeedingIndexes();
      this.#calculateMaxHeights();
      this.mostRecentFeedingNumber = _.max(
        _.map(feedingNumbersWithVitals, Number)
      );

      this.heightChart = this.setupHeightChart();

      if (this.hasTempChartTarget) {
        this.tempChart = this.setupTempChart();
        addTemperature(
          this.tempChart,
          this.mostRecentFeedingNumber,
          this.vitals[this.mostRecentFeedingNumber],
          this.feedingIndexes,
          this.singleFeedingValue,
          this.celsiusValue
        );
      }

      this.setupMaxHeights(this.maxHeights);

      this.updateFeedingTable(this.vitals, this.maxHeights);

      if (singleFeeding) {
        addFeeding(
          this.heightChart,
          this.mostRecentFeedingNumber,
          this.vitals[this.mostRecentFeedingNumber],
          this.maxHeights,
          this.feedingIndexes,
          this.singleFeedingValue
        );
      } else {
        $(".selected").each((i, f) => {
          let feedingNumber = $(f).attr("id");
          addFeeding(
            this.heightChart,
            feedingNumber,
            this.vitals[feedingNumber],
            this.maxHeights,
            this.feedingIndexes,
            this.singleFeedingValue
          );
        });
      }
    }
  }

  loadFeeding(e) {
    // TODO: Move from jquery to using Stimulus targets
    e.preventDefault();

    const f = $(e.target);
    const feedingNumber = f.attr("id");

    if (f.hasClass("selected") && $(".load_feeding.selected").length === 1) {
      return;
    }

    f.toggleClass("btn-white");
    f.toggleClass(
      "btn-outline-" + getFeedingColor(this.feedingIndexes[feedingNumber])
    );
    f.toggleClass("btn-" + getFeedingColor(this.feedingIndexes[feedingNumber]));
    f.toggleClass("selected");

    if (f.hasClass("selected")) {
      addFeeding(
        this.heightChart,
        feedingNumber,
        this.vitals[feedingNumber],
        this.maxHeights,
        this.feedingIndexes,
        this.singleFeedingValue
      );
      addTemperature(
        this.tempChart,
        feedingNumber,
        this.vitals[feedingNumber],
        this.feedingIndexes,
        this.singleFeedingValue,
        this.celsiusValue
      );
    } else {
      if ($(".load_feeding.selected").length - 1 >= 0) {
        removeFeeding(this.heightChart, feedingNumber);
        removeFeeding(this.tempChart, feedingNumber);
      }
    }
    e.preventDefault();
  }

  updateFeedingTable(vitals, maxHeights) {
    let averageMaxHeight = 0;
    let averageRipeness = 0;

    _.each(maxHeights, (m, feedingNumber) => {
      const ripeness = vitals[feedingNumber].filter((v) => v.ripe)[0];

      if (ripeness) {
        const timeToRipeness = moment(ripeness.tick).diff(
          vitals[feedingNumber][0].fed_at
        );
        averageRipeness += timeToRipeness;
      }

      $(`.average-temperature.feeding_${feedingNumber}`).html(
        _.meanBy(vitals[feedingNumber], (v) =>
          toCorrectTempUnit(v.temperature, this.celsiusValue)
        ).toFixed(2) +
          " " +
          tempUnitLabel(this.celsiusValue)
      );

      const riseMultiplier = m.height.toFixed(2);

      $(`.max-height.feeding_${feedingNumber}`).html(riseMultiplier + "X");

      averageMaxHeight += m.height;
    });

    // Set average rise if not set by backend
    if (!$(".average-max-height").text().trim()) {
      const averageRise = (
        averageMaxHeight / Object.keys(maxHeights).length
      ).toFixed(2);
      $(".average-max-height").text(averageRise + "X");
    }

    $(".average-time-to-ripeness").text(
      moment.duration(averageRipeness).humanize()
    );
  }

  #calculateFeedingIndexes() {
    this.feedingIndexes = Object.fromEntries(
      _.zip(
        _.keys(this.vitals),
        _.range(0, _.keys(this.vitals).length).reverse()
      )
    );
  }

  #calculateMaxHeights() {
    this.maxHeights = Object.fromEntries(
      _.chain(this.feedingsValue)
        .map((f) =>
          _.maxBy(this.vitals[f.number], (d) => {
            return d.height;
          })
        )
        .compact()
        .map((m) => {
          if (m && m.feeding_number !== undefined) {
            return [m.feeding_number, { height: m.height, x: m.x }];
          }
        })
        .value()
    );
  }
}
