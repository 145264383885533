import { Chart } from "chart.js";
import _ from "lodash";
import moment from "moment-timezone";

export const setupHeightChart = (ctx) => {
  return new Chart(ctx, {
    type: "line",
    data: {
      datasets: [],
    },
    options: {
      responsive: true,
      tooltips: {
        mode: "nearest",
        intersect: true,
        callbacks: {
          title: function (tooltipItem, data) {
            var currentTick = data.datasets[tooltipItem[0].datasetIndex];
            return `Feeding #${currentTick.number} ${_.capitalize(
              currentTick.starterState
            )}`;
          },
          label: function (tooltipItem, data) {
            var currentTick =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            if (data.datasets[tooltipItem.datasetIndex].temperature) {
              return (
                currentTick.y.toFixed(2) +
                "º @ " +
                moment(currentTick.tick).format("LT")
              );
            } else {
              return (
                currentTick.y.toFixed(2) +
                "X @ " +
                moment(currentTick.tick).format("LT") +
                " +" +
                moment(currentTick.tick)
                  .diff(
                    data.datasets[tooltipItem.datasetIndex].fedAt,
                    "hours",
                    true
                  )
                  .toFixed(0) +
                " hrs after feeding"
              );
            }
          },
        },
      },
      events: ["mousemove"],
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      animation: {
        duration: 0,
      },
      elements: {
        point: {},
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            id: "height-y-axis-0",
            scaleLabel: {
              labelString: "Growth Factor",
              fontFamily: "Bianco Sans",
              fontSize: 18,
              lineHeight: 1.5,
              display: true,
            },
            ticks: {
              fontFamily: "Bianco Sans",
              suggestedMin: 0.8,
              suggestedMax: 2,
              callback: (value) => {
                return value.toFixed(1) + "X";
              },
            },
          },
        ],
        xAxes: [
          {
            id: "height-x-axis-0",
            type: "linear",
            display: true,
            scaleLabel: {
              labelString: "Hours after feeding",
              fontFamily: "Bianco Sans",
              fontSize: 18,
              lineHeight: 1.5,
              display: true,
            },
            ticks: {
              stepSize: 60 * 3,
              fontFamily: "Bianco Sans",
              suggestedMax: 12*60,
              callback: (value) => {
                if (value === 0) {
                  return;
                } else {
                  return "+" + value / 60 + " hrs";
                }
              },
            },
          },
        ],
      },
    },
  });
};
