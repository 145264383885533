import { DirectUpload } from "@rails/activestorage";
import Dropzone from "dropzone";

const uploadsCompleted = {};

export default () => {
  document
    .querySelectorAll(".dropzone-container")
    .forEach((dropzoneContainer) => {
      const fileInput = dropzoneContainer.querySelector("input[type=file]");

      const bannerImage = fileInput
        .closest("form")
        .querySelector("input.banner-image");

      const submitButton = fileInput
        .closest("form")
        .querySelector('[type="submit"]');

      const dropzone = new Dropzone(dropzoneContainer, {
        url: fileInput.getAttribute("data-direct-upload-url"),
        paramName: fileInput.name,
        autoProcessQueue: false,
        clickable: ".file-upload",
        maxFiles: fileInput.dataset.singleFile ? 1 : 256,
        removedfile: (file) =>
          removeImage(file.previewElement, bannerImage, fileInput),
        reset: () => {
          if (fileInput.dataset.withBanner) {
            fileInput
              .closest("form")
              .querySelector("input.banner-image")
              .removeAttribute("value");
          }
        },
        maxfilesexceeded: (file) => {
          dropzone.removeAllFiles();
          dropzone.addFile(file);
        },
        addedfiles: (files) => {
          submitButton.disabled = true;
          Array.from(files).forEach((file, i) => {
            uploadsCompleted[file.name] = false;
            if (
              fileInput.dataset.withBanner &&
              i === 0 &&
              bannerImage.getAttribute("value") === null
            ) {
              // Make first uploaded image banner image
              uploadFile(fileInput)(file, true);
              file.previewElement.querySelector(".toggle-banner").textContent =
                "Current Banner Image";
              file.previewElement
                .querySelector(".toggle-banner")
                .removeAttribute("href");
            } else {
              uploadFile(fileInput)(file);
            }
            if (fileInput.dataset.withBanner) {
              file.previewElement
                .querySelector(".toggle-banner")
                .addEventListener("click", toggleBannerImage(bannerImage));
            }

            if (fileInput.dataset.hideOnUpload) {
              dropzoneContainer
                .querySelectorAll(".hide-on-upload")
                .forEach((container) => container.classList.add("d-none"));
              file.previewElement
                .querySelector(".dz-clickable")
                .addEventListener("click", (e) => {
                  e.preventDefault();
                  fileInput.click();
                });
            }
          });
        },
        acceptedFiles: "image/png, image/jpeg",
        previewTemplate: getPreviewTemplate(),
        thumbnailHeight: 70,
        thumbnailWidth: 80,
      });

      //set up event listeners for existing images
      dropzoneContainer
        .querySelectorAll(".dz-preview:not(.d-none)")
        .forEach((previewElement) => {
          previewElement
            .querySelector(".remove-image")
            .addEventListener("click", (e) =>
              removeImage(previewElement, bannerImage, fileInput, e)
            );
          previewElement
            .querySelector(".toggle-banner")
            .addEventListener("click", toggleBannerImage(bannerImage));
        });
    });
};

const getPreviewTemplate = () => {
  const template = $(".dz-preview").clone(true, true);
  template.removeClass("d-none");
  return template.html();
};

const uploadFile = (input) => (file, makeBanner) => {
  const url = input.dataset.directUploadUrl;
  const upload = new DirectUpload(file, url);
  const submitButton = input.closest("form").querySelector('[type="submit"]');

  upload.create((error, blob) => {
    if (!error) {
      const hiddenField = document.createElement("input");
      const form = input.closest("form");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", blob.signed_id);
      hiddenField.name = input.name;
      form.appendChild(hiddenField);

      uploadsCompleted[file.name] = true;
      if (Object.keys(uploadsCompleted).every((k) => uploadsCompleted[k])) {
        submitButton.disabled = false;
      }
      if (input.dataset.withBanner) {
        file.previewElement.querySelector(".toggle-banner").dataset.signedId =
          blob.signed_id;
        if (makeBanner) {
          form
            .querySelector("input.banner-image")
            .setAttribute("value", blob.signed_id);
        }
      }
    }
  });
};

const removeImage = (previewElement, bannerImage, fileInput, e) => {
  if (e) e.preventDefault();

  if (fileInput.dataset.withBanner) {
    const fileSignedId = previewElement.querySelector(".toggle-banner").dataset
      .signedId;
    fileInput
      .closest("form")
      .querySelectorAll(`input[value=${fileSignedId}]:not(.banner-image)`)
      .forEach((i) => i.remove());
    const currentSignedId = bannerImage.getAttribute("value");
    const newBanner = fileInput
      .closest("form")
      .querySelectorAll(
        `.toggle-banner:not([data-signed-id=${currentSignedId}`
      )[1];
    if (currentSignedId === fileSignedId && newBanner) {
      newBanner.textContent = "Current Banner Image";
      newBanner.removeAttribute("href");
      bannerImage.setAttribute("value", newBanner.dataset.signedId);
    }
  }
  previewElement.remove();
};

const toggleBannerImage = (bannerImage) => (e) => {
  e.preventDefault();
  const link = e.target;
  if (link.dataset.signedId !== bannerImage.getAttribute("value")) {
    bannerImage.setAttribute("value", link.dataset.signedId);
    link.textContent = "Current Banner Image";
    link.removeAttribute("href");
    document.querySelectorAll(".toggle-banner").forEach((l) => {
      if (l !== link) {
        l.textContent = "Make Banner Image";
        l.href = "";
      }
    });
  }
};
