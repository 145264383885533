/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//

require("lightbox2");
require("path-data-polyfill");

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import setupDropzones from "./setupDropzones";

import * as channels from "../channels";
import "../controllers";

const notificationConfigured = () => {
  if ("Notification" in window) {
    return ["granted", "denied"].includes(Notification.permission);
  } else {
    // This branch will be only be executed when on iOS Safari
    // In this case, we want the rest of the code to act as if notifications are already configured and not bother the user about them.
    return true;
  }
};

$(document).ready((e) => {
  $("input[type='file']").on("change", (e) => {
    $(e.target)
      .parent()
      .siblings("label")
      .text([...e.target.files].map((f) => f.name).join(", "));
  });

  if (notificationConfigured()) {
    $(".request-permission").hide();
  }

  $("#request-permission").on("click", async (e) => {
    const permission = await Notification.requestPermission();
    if (notificationConfigured()) {
      $(".request-permission").hide();
    }
  });

  setupDropzones();
});
