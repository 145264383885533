import { Chart } from "chart.js";
import { getColorHex, maxHeightColor } from "./graphColorHelpers";

export const setupMaxHeights = (ctx, maxHeights) => {
  const labels = Object.keys(maxHeights);

  return new Chart(ctx, {
    type: "bar",
    data: {
      labels: labels.map((l) => "Feeding #" + l),
      datasets: [
        {
          borderColor: getColorHex("tan"),
          backgroundColor: (context) => {
            return maxHeightColor(
              context.dataset.data[context.dataIndex],
              false
            );
          },
          borderWidth: 1,
          data: labels.map((l) => maxHeights[l].height.toFixed(2)),
        },
      ],
    },
    options: {
      responsive: true,
      animation: {
        duration: 0,
      },
      legend: {
        display: false,
      },
      events: ["mousemove"],
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: 1,
            },
          },
        ],
      },
    },
  });
};
